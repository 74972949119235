




























































































































































































































































import { Effect } from "@/model/menu";
import ShopAPI from "../../common/api/api_shop";
import Vue from "vue";
import _ from "lodash";
import moment from "moment";
import store from "../../store/shop";
import notificationMixin from "../../mixin/notification";
import { TranslateServiceBuilder } from "../../services/Translate/translateServiceBuilder";
import * as constant from "../../common/util/constant";

const translate = TranslateServiceBuilder.Instance()._;
const api = new ShopAPI();

Vue.directive('enhance-defaulot-value', {
  bind: function (el) {
    el.addEventListener('focus', function() {
      const value = el.value;
      if (value == 0) {
        el.value = '';
      }
    });
    el.addEventListener('blur', function() {
      const value = el.value;
      if (value == '') {
        el.value = 0;
      }
    });
  }
});

export default Vue.component("Category", {
  components: {
    SHOrderCardSkeleton: () =>
      import("../../components/components/SHOrderCardSkeleton.vue"),
    QError: () => import("../../components/elements/QError.vue"),
    QLink: () => import("../../components/elements/QLink.vue"),
    QTranslate: () => import("../../components/elements/QTranslate.vue"),
  },
  data() {
    return {
      categories: [],
      category_group_temps: [],
      category_groups: [],
      orders: [],
      summary: [],
      tableSessionId: this.$route.params.table_session_id,
      newMenu: {},
      isCheck: false,
      errors: "",
      mode: "",
      isLoading: false,
      categoryTypeList: {},
      speedList: {},
      categoryList: [],
      effects: [],
      random: 1,
    };
  },
  mixins: [notificationMixin],
  mounted() {
    api.set_token(store.state.token);
    this.initalNewMenu();
    this.fetchCategories();
    store.commit("setSelectedCategory", {});
    this.intervalCheckAvaiable();
  },
  destroyed() {
    this.interval && clearInterval(this.interval);
  },
  watch: {
    menuChange(newVal, oldVal) {
      console.log('menuChange', newVal);
      if (newVal != '') {
        this.fetchCategories();
      }
    }
  },
  computed: {
    zeiritsu(): string {
      if (
        this.categoryList[this.newMenu.category_id] === 2 &&
        this.newMenu.is_liquor_tax === false
      ) {
        return " 税率：8%（軽減税率対象商品）";
      } else {
        return " 税率：10%";
      }
    },
    isModalCancel(): boolean {
      return this.mode === "cancel";
    },
    isModalMenu(): boolean {
      return this.mode === "menu";
    },
    isModalOption(): boolean {
      return this.mode === "option";
    },
    prefix() {
      return this.$route.params.prefix;
    },
    shoppix() {
      return this.$route.params.shoppix;
    },
    totalQuantity(): number {
      let target = _.find(
        this.summary,
        (summary) => summary.tax_rate == null
      );

      return (target) ? target.quantity : 0;
    },
    effectLimitTitle: () => (effect: Effect) => {
      if (effect.end_time) {
        const endTime = moment(effect.end_time).format("HH:mm");
        const lastTime = moment(effect.last_order_time).format("HH:mm");
        return `${endTime} (L.O. ${lastTime})`;
      } else {
        return "--:-- (L.O. --:--)";
      }
    },
    isEffectAvaiable() {
      // enable if not set limit item
      this.random; // force recomputed
      return (effect: Effect) => {
        if (!effect.limit_option) {
          return true;
        }
        // check valid time
        const now = moment();
        return (
          effect.start_time &&
          moment(effect.start_time).isBefore(now) &&
          moment(effect.last_order_time).isAfter(now)
        );
      }
    },
    effectStatus() {
      // enable if not set limit item
      this.random; // force recomputed
      return (effect: Effect): "active" | "prepare" | "end" => {
        if (!effect.limit_option) {
          return "active";
        }

        if (!effect.end_time) {
          return "prepare";
        }

        // check valid time
        const now = moment();
        const check =
          effect.start_time &&
          moment(effect.start_time).isBefore(now) &&
          moment(effect.last_order_time).isAfter(now);

        if (!check) {
          return "end";
        }

        return "active";
      }
    },
    menuChange() {
      return store.state.pageChange.menu;
    }
  },
  methods: {
    // Header
    initalNewMenu() {
      this.newMenu = {
        shop_id: "",
        rank: 10000,
        is_enable: true,
        is_customer: false,
        creator: "hall",
        table_session_id: "",
        name: "",
        is_liquor_tax: false,
        category_id: "",
        speed_key: 1,
        price: "0",
        quantity: "0",
      };
    },
    setTableSession() {
      if (!this.tableSession) {
        return;
      }
      store.commit("setTableSession", this.tableSession);
    },
    setCategories() {
      if (!this.categories) {
        return;
      }

      store.commit("setCategories", this.categories);
    },
    setCategoryGroups() {
      if (!this.category_group_temps) {
        return;
      }

      store.commit("setCategoryGroups", this.category_group_temps);
    },
    fetchCategories() {
      this.isLoading = true;
      let message = "";
      api
        .order_home({ table_session_id: this.tableSessionId })
        .then((response) => {
          if (response.data.status !== "success") {
            message = response.data.message || "データの取得に失敗しました。";
            this.showErrorNotification(message);
            this.errors = message;
            return;
          }
          this.isLoading = false;
          this.isCheck = false;
          this.categories = response.data.data.categories;
          this.category_group_temps = response.data.data.category_groups;
          this.category_groups = [];
          _.forEach(this.category_group_temps, (category_group) => {
            if(!this.hasValidCategory(category_group)){
              return;
            }

            if (!category_group.parent_id) {
              category_group.type = 1;

              if (category_group.category_id) {
                let category = this.getCategory(category_group.category_id)
                category_group.name = category.name;
                category_group.type = category.type;
              }

              category_group.link = `/${this.prefix}/${this.shoppix}/table-session/order/${this.tableSessionId}/categories/${category_group.category_id}`;

              if (this.hasChild(category_group.id)) {
                category_group.link = `/${this.prefix}/${this.shoppix}/table-session/order/${this.tableSessionId}/category_group/${category_group.id}`;
              }

              this.category_groups.push(category_group);
            }
          });

          this.category_groups = _.orderBy(this.category_groups, ['rank'],['asc']);
          this.categoryTypeList = response.data.data.categoryTypeList;
          this.categoryList = response.data.data.categoryList;
          this.speedList = response.data.data.speedList;
          this.tableSession = response.data.data.tableSession;
          this.orders = response.data.data.orders;

          let effects = _.chain(response.data.data.effects)
            .orderBy("last_order_time")
            .orderBy((item) => !item.limit_option)
            .groupBy("order_id")
            .value();

          this.effects = effects;
          this.newOrder = response.data.data.newOrder;
          this.isNaizei = response.data.data.tableSession.shop.is_naizei;
          // set new menu
          this.newMenu.shop_id = this.tableSession.shop_id;
          this.newMenu.table_session_id = this.tableSessionId;
          this.setTableSession();
          this.setCategoryGroups();
          this.setCategories();
          this.summary = response.data.data.summary;
        })
        .catch((err) => {
          this.isLoading = false;
          message = err.response.data.message || "データの取得に失敗しました。";
          this.showErrorNotification(message);
          this.errors = message;
        });
    },
    getCategory(id){
      return _.find(this.categories, (category) => category.id === id);
    },
    hasChild(id){
      let result = false;

      let child = _.find(this.category_group_temps, (category_group) => category_group.parent_id === id);

      return !!child;
    },
    hasValidCategory(category_group) {
      let result = false;

      if (category_group.category_id) {
        let category = this.getCategory(category_group.category_id);

        if (category) {
          result = true;
        }
      }

      let childs = _.filter(this.category_group_temps, (categoryGroup) => categoryGroup.parent_id === category_group.id);

      _.forEach(childs, (child) => {
        result = result ? result : this.hasValidCategory(child);
      });

      return result;
    },
    // キャンセル
    handleClickCancel: function () {
      this.mode = "cancel";
    },
    handleClickReturn: function () {
      this.mode = "menu";
    },
    // 新規メニュー作成
    handleClickNewMenu: function () {
      if (this.isLoading) {
        return;
      }

      this.mode = "menu";
    },
    getStatus: function (item) {
      if (this.isCheck === false) {
        return "";
      }
      if (!item) {
        return "error";
      }
      return "";
    },
    handleClickSubmitMenu: function () {
      if (this.isLoading) {
        return;
      }
      this.isCheck = true;
      this.isLoading = true;
      let message = "";
      api
        .order_menu({
          shop_id: this.newMenu.shop_id,
          rank: this.newMenu.rank,
          is_enable: this.newMenu.is_enable,
          is_customer: this.newMenu.is_customer,
          creator: this.newMenu.creator,
          table_session_id: this.newMenu.table_session_id,
          name: this.newMenu.name,
          is_liquor_tax: this.newMenu.is_liquor_tax,
          category_id: this.newMenu.category_id,
          speed_key: this.newMenu.speed_key,
          price: this.newMenu.price,
          quantity: this.newMenu.quantity,
          minimum_customer_order: 1,
          display_period_option: false,
          a_la_carte_display: 1,
          is_set_maximum_order_per_day: false,
          limit_option: false
        })
        .then((response) => {
          if (response.data.status !== "success") {
            message = response.data.message || "保存に失敗しました。";
            this.showErrorNotification(message);
            this.errors = message;
            this.isLoading = false;
            return;
          }
          message = response.data.message;
          // this.showSuccessNotification(message); // ODR-964
          this.mode = "";
          this.initalNewMenu();
          this.fetchCategories();
        })
        .catch((err) => {
          message = err.response.data.message || "保存に失敗しました。";
          this.showErrorNotification(message);
          this.errors = err.response.data.data;
          this.isLoading = false;
        });
    },
    toCatStr(catId: number) {
      switch (catId) {
        case 1:
          return "";
        case 2:
          return "takeout";
        case 3:
          return "catering";
      }

      return "";
    },
    getMenuNameById(id) {
      const result = _.chain(this.categories)
        .map("menus")
        .flatten()
        .filter({ id: id })
        .value();

      if (result[0]) {
        return result[0]["name"];
      }

      return null;
    },
    intervalCheckAvaiable() {
      this.interval = setInterval(() => {
        this.random = Date.now();
      }, constant.TIME_RE_COMPUTED);
      // 30 * 60
    },
  },
  filters: {
    toStatusLabel(status: boolean) {
      if (!status) {
        return "end";
      }

      return "";
    },
    toStatusText(status: "prepare" | "active" | "end"): string {
      switch (status) {
        case "prepare":
          return translate("hall-0224", "準備中");
        case "active":
          return "";
        case "end":
          return translate("hall-0223", "終了");
      }
    },
  },
});
